import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import ogImage from '../images/bg.jpg'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faRocket  } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { StaticQuery, graphql } from 'gatsby'
import "fontsource-source-sans-pro/300.css"
import "fontsource-source-sans-pro/600.css"
import '../assets/scss/main.scss'

const Layout = ({ children, location }) => {

  let content;

  library.add(faRocket, faTwitter, faLinkedin, faInstagram);

  content = (
    <div>
      {children}
    </div>
  )

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (

        <>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: 'keywords', content: 'strategy, director, web development, ux design, developer, strategist, senior developer, marketing' },
              { name: 'author', content: 'Ryan Benhase' },
              { prefix: "og: http://ogp.me/ns#", property: "og:url", content: 'https://ryanbenhase.com/' },
              { prefix: "og: http://ogp.me/ns#", name: "title", property: "og:title", content: 'Ryan Benhase / Cincinnati, Ohio' },
              { prefix: "og: http://ogp.me/ns#", name: 'description', property: "og:description", content: 'Change agent, creative thinker, developer, and marketer located in Cincinnati, Ohio.' },
              { prefix: "og: http://ogp.me/ns#", name: "image", property: "og:image", content: "https://ryanbenhase.com" + ogImage },
              { prefix: "og: http://ogp.me/ns#", property: "og:image:height", content: "800" },
              { prefix: "og: http://ogp.me/ns#", property: "og:image:width", content: "1200" },
              { prefix: "og: http://ogp.me/ns#", property: "og:type", content: 'website' },
              { name: "twitter:title", content: 'Ryan Benhase / Cincinnati, Ohio' },
              { name: "twitter:description", content: 'Change agent, creative thinker, developer, and marketer located in Cincinnati, Ohio.' },
              { name: "twitter:image", content: "https://ryanbenhase.com" + ogImage },
              { name: "twitter:card", content: 'website' },
              { name: "twitter:creator", content: 'ryanbenhase' },
            ]}
          >
            <html lang="en" />
          </Helmet>
          {content}
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
